import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {closePanel} from '@Components/panel/panel-reducer';
import useCustomPanelClose from '@Panels/hooks/useCustomPanelClose';
import {Panel} from '@Components/panel';
import {DownloadPDFReportHeader} from '@Panels/email-metrics-download-pdf-panel/components/download-pdf-report-header';
import {DownloadPDFReportContent} from '@Panels/email-metrics-download-pdf-panel/components/download-pdf-report-content';
import {DownloadPDFReportFooter} from '@Panels/email-metrics-download-pdf-panel/components/download-pdf-report-footer';
import {useReactToPrint} from 'react-to-print';
import {validateActiveBrandForCurrentUser} from '@Libraries/brands-library';
import {openErrorModal} from '@Modals/error-modal';
import {getFirstSelectedEmailMarketingCampaign, sanitizeFilename} from '@Libraries/email-marketing-campaigns-library';
import type {EmailMarketingCampaign} from '@Components/email-marketing-campaign-performance/email-marketing-campaign-performance.types';
import {GA4EventName} from '@Libraries/ga-events';
import styles from './email-metrics-download-pdf-panel.module.scss';
import {useAppDispatch} from '@/hooks';

export const PANEL_ID = 'email-metrics-download-pdf-panel';

interface EmailMetricsDownloadPDFPanelProps {
  dialogMode: boolean;
  panelId: string;
}

enum PDF_PAGE_SETTINGS {
  US_LETTER = '@page { size: letter }',
  A4 = '@page {size: A4 }',
}

/**
 * The panel that opens up in the PDF download wizard when the campaign metrics 'download' option is clicked
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export function EmailMetricsDownloadPDFPanel({dialogMode = false, panelId = PANEL_ID}: EmailMetricsDownloadPDFPanelProps): ReactElement {
  const dispatch = useAppDispatch();
  const pdfRef = React.useRef<HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onBeforeGetContentResolve = React.useRef((value: any) => {});
  const [inSelectMode, setInSelectMode] = useState(true);
  const [showOpeningBrandKitsLoader, setShowOpeningBrandKitsLoader] = useState(false);
  const [isReportPreviewOpenInMobileMode, setIsReportPreviewOpenInMobileMode] = useState(true);
  const [loadingPDFRender, setLoadingPDFRender] = React.useState(false);
  const selectedEmailCampaign: EmailMarketingCampaign | null = getFirstSelectedEmailMarketingCampaign() as EmailMarketingCampaign | null;

  useEffect(() => {
    window.PMW.initTracking();
  }, []);

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current(true);
    }
  }, [onBeforeGetContentResolve.current]);

  const handleAfterPrint = React.useCallback(() => {
    setLoadingPDFRender(false);
    onBeforeGetContentResolve.current(true);

    window.PMW.gtm.trackGA4CustomEvent(GA4EventName.DOWNLOAD_CAMPAIGN_PDF);
  }, []);

  const handleBeforePrint = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      void validateActiveBrandForCurrentUser()
        .then((): void => {
          resolve(true);
        })
        .catch(() => {
          openErrorModal({message: window.i18next.t('pmwjs_you_do_not_have_permission_to_download_this_pdf')});
          reject();
        });
    });
  };

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoadingPDFRender(true);

    if (pdfRef.current) {
      pdfRef.current.style.height = '100%';
      pdfRef.current.style.width = '100%';
      pdfRef.current.style.padding = '0';
      pdfRef.current.style.maxWidth = 'unset';
      pdfRef.current.style.maxHeight = 'unset';
      pdfRef.current.style.border = 'none';
      pdfRef.current.style.boxShadow = 'none';
      pdfRef.current.style.overflow = 'hidden';
    }
  }, [setLoadingPDFRender]);

  const reactToPrintContent = React.useCallback(() => {
    return pdfRef.current;
  }, [pdfRef.current]);

  const getPDFDocumentTitle = (): string => {
    return `${sanitizeFilename(selectedEmailCampaign?.name) as string} - ${window.i18next.t('pmwjs_email_campaign_report')}.pdf`;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: getPDFDocumentTitle(),
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    pageStyle: PDF_PAGE_SETTINGS.US_LETTER,
    bodyClass: styles.printPageBody,
  });

  const close = (): void => {
    dispatch(closePanel(panelId));
  };

  const onClose = useCustomPanelClose(panelId, close);

  const getPanelFooter = (): {panelFooter: React.JSX.Element} | null => {
    if (!inSelectMode || showOpeningBrandKitsLoader) {
      return null;
    }

    return {
      panelFooter: (
        <DownloadPDFReportFooter
          isReportPreviewOpenInMobileMode={isReportPreviewOpenInMobileMode}
          setIsReportPreviewOpenInMobileMode={setIsReportPreviewOpenInMobileMode}
          loadingPDFRender={loadingPDFRender}
          onClickDownloadReport={handlePrint}
        />
      ),
    };
  };

  return (
    <Panel
      className={`radius-8 ${styles.justifyChildDivSpaceBetween}`}
      panelHeader={
        <DownloadPDFReportHeader
          isInModal={dialogMode}
          onClose={onClose}
          setInSelectMode={setInSelectMode}
          inSelectMode={inSelectMode}
          setShowOpeningBrandKitsLoader={setShowOpeningBrandKitsLoader}
          loadingScreenBeingShown={showOpeningBrandKitsLoader}
          isReportPreviewOpenInMobileMode={isReportPreviewOpenInMobileMode}
          setIsReportPreviewOpenInMobileMode={setIsReportPreviewOpenInMobileMode}
        />
      }
      panelContent={
        <DownloadPDFReportContent
          isInModal={dialogMode}
          onClose={onClose}
          setInSelectMode={setInSelectMode}
          inSelectMode={inSelectMode}
          setShowOpeningBrandKitsLoader={setShowOpeningBrandKitsLoader}
          showOpeningBrandKitsModal={showOpeningBrandKitsLoader}
          isReportPreviewOpenInMobileMode={isReportPreviewOpenInMobileMode}
          pdfRef={pdfRef}
        />
      }
      {...getPanelFooter()}
    />
  );
}
