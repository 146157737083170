import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {MessagePanelProps} from '@Panels/message-panel';
import {PanelHeader} from '@Components/panel';
import {MODALS_IDS} from '@Components/modal-container';
import {useDispatch} from 'react-redux';
import {LazyPanel} from '@Components/lazy-panel';
import type {UploadingImagesPanelProps} from '@Panels/uploading-images-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'uploading-images-panel';

export interface UploadingImagesModalProps extends UploadingImagesPanelProps {
  height?: string;
  width?: string;
  title?: string;
  onClose?(): void | Promise<void>;
}

export function UploadingImagesModal(): ReactElement {
  const panelProps = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.UPLOADING_IMAGES_MODAL].panelProps as UploadingImagesModalProps;
  });
  const dispatch = useDispatch();

  const height = panelProps.height ?? '355px';
  const width = panelProps.width ?? '540px';

  const onModalClose = async (): Promise<void> => {
    dispatch(closeModal(MODALS_IDS.UPLOADING_IMAGES_MODAL));
    if (panelProps.onClose) {
      await panelProps.onClose();
    }
  };

  return (
    <Modal modalId={MODALS_IDS.UPLOADING_IMAGES_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="uploading-images-panel"
        panelProps={
          {
            ...panelProps,
            header: panelProps.header ?? <PanelHeader title={panelProps.title} onClose={onModalClose} />,
            panelId: PANEL_ID,
          } as MessagePanelProps
        }
      />
    </Modal>
  );
}

export const openUploadingImagesModal = (props: UploadingImagesModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.UPLOADING_IMAGES_MODAL,
      panelProps: props,
    })
  );
};
