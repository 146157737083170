import type {CreateSignupFormModalProps} from '@Modals/create-signup-form-modal/create-signup-form-modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';

export * from './create-signup-form-modal';
export const PANEL_ID = 'create-signup-form-panel';

export const openCreateSignupFormModal = (props: CreateSignupFormModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.CREATE_SIGNUP_FORM_MODAL,
      panelProps: props,
    })
  );
};
