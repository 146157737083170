import type {ReactElement} from 'react';
import React from 'react';
import {Panel} from '@Components/panel';
import {CreateEventHeader} from '@Panels/create-content-planner-event-panel/components/create-event-header';
import useCustomPanelClose from '@Panels/hooks/useCustomPanelClose';
import {CreateEventContent} from '@Panels/create-content-planner-event-panel/components/create-event-content';
import {setIsCustomEventBeingCreated} from '@Components/content-planner/content-planner-reducer';
import {closePanel} from '@Components/panel/panel-reducer';
import type {ContentPlannerCustomEventsPanelParams} from '@Components/content-planner/content-planner.types';
import {useAppDispatch} from '@/hooks';

export const PANEL_ID = 'create-content-planner-event-panel';

function CreateContentPlannerEventPanel({dialogMode = false, panelId = PANEL_ID}: ContentPlannerCustomEventsPanelParams): ReactElement {
  const dispatch = useAppDispatch();
  const close = (): void => {
    dispatch(setIsCustomEventBeingCreated(false));
    dispatch(closePanel(panelId));
  };

  const onClose = useCustomPanelClose(panelId, close);

  return (
    <Panel
      className="radius-8"
      panelHeader={<CreateEventHeader isInModal={dialogMode} onClose={onClose} />}
      panelContent={<CreateEventContent isInModal={dialogMode} onClose={onClose} />}
    />
  );
}

export default CreateContentPlannerEventPanel;
