import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {PANEL_ID} from '@Panels/email-metrics-download-pdf-panel/email-metrics-download-pdf-panel';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {useModalData} from '@Components/modal/hooks/useModalData';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import useWindowSize from '@Hooks/useWindowSize';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';

/**
 * The modal for PDF download wizard when the campaign metrics 'download' option is clicked
 * It shows either the brands kit or the upsell screen or the
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export function EmailMetricsDownloadPDFModal(): ReactElement {
  const modalData = useModalData(MODALS_IDS.EMAIL_METRICS_DOWNLOAD_PDF_MODAL);
  const {windowHeight, windowWidth} = useWindowSize();
  const height = windowHeight - 64; // 64px padding from top and bottom each so * 2
  const width = windowWidth < Number(SCREEN_BREAKPOINTS.MOBILE_MODAL_ACTIVATION_BREAKPONT) ? windowWidth : windowWidth - 64 * 2; // 64px padding from left and right each so * 2

  return (
    <Modal
      modalId={MODALS_IDS.EMAIL_METRICS_DOWNLOAD_PDF_MODAL}
      isBottomSheetOnMobile
      panelId={PANEL_ID}
      mobileHeight="590px"
      modalHeight={`${height}px`}
      modalWidth={`${width}px`}
      modalMaxWidth="1420px"
      stickToBottom
    >
      <LazyPanel
        panelDirectoryName="email-metrics-download-pdf-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
          modalId: MODALS_IDS.EMAIL_METRICS_DOWNLOAD_PDF_MODAL,
          dialogMode: true,
        }}
      />
    </Modal>
  );
}

export const openEmailMetricsDownloadPDFModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.EMAIL_METRICS_DOWNLOAD_PDF_MODAL,
      panelProps: {},
    })
  );
};
