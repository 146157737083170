import {MODALS_IDS} from '@Components/modal-container';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';

export const BUSINESS_PROFILES_ACTIVITY_PANEL_ID = 'business-profiles-activity-panel';

export const openBusinessProfilesActivityModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.BUSINESS_PROFILES_ACTIVITY_MODAL,
      panelProps: {},
    })
  );
};
