import type {AJAXResponseProductData, DisplayType, ProductType, SelectionType} from '@Panels/poster-publishing-options-panel/poster-publishing-options-panel.types';
import type {SocialMediaResponse} from '@Panels/poster-publishing-options-panel/components/publishing-social-media-panel/publishing-social-media-panel.types';
import type {OrderDetailsForRedux} from '@Panels/poster-publishing-options-panel/components/publishing-embed-in-webpage-panel/publishing-embed-in-webpage-panel.types';
import type {SubFlowType} from '@Panels/poster-publishing-options-panel/components/publishing-digital-signage-list/components/digital-signage-compiled-sub-panel/digital-signage-compiled-sub-panel.types';
import {useAppSelector} from '@/hooks';

// codeReviewSarimDone: none of these are hooks
// for React to recognise these as proper hooks, you need to prefix all hooks with 'use'
// so it should be useSelectionType, and so on
// also handle ESLint warnings

export const useSelectionType = (): SelectionType => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.selectionType;
    });
};
export const useDisplayType = (): DisplayType => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.displayType;
    }) as DisplayType;
};
export const useShowLoader = (): boolean => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.showLoader;
    });
};
export const useHashedId = (): string | undefined => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.premiumProductDataForPrint?.hashedId;
    });
};
export const useFreebieProduct = (): AJAXResponseProductData | undefined => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.freebieProductData;
    });
};

export const usePrintProduct = (): AJAXResponseProductData | undefined => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.premiumProductDataForPrint;
    });
};

export const usePosterProduct = (): AJAXResponseProductData | undefined => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.premiumProductDataForPoster;
    });
};

export const useOrderDetails = (): OrderDetailsForRedux => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.orderDetails;
    });
};

export const useProductType = (): ProductType | undefined => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.productType;
    });
};

export const useSocialMediaLinks = (): SocialMediaResponse => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.socialMediaLinks;
    });
};

export const useSubFlowType = (): SubFlowType => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.subFlowType;
    });
};

export const useErrorMessageForPublishing = (): string => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.errorMessage;
    });
};

export const useShowError = (): boolean => {
    return useAppSelector((state) => {
        return state.publishingOptionsPanel.showError;
    });
};

// codeReviewSarimDone: this shouldnt belong in a hooks file
// rule of thumb: make a hook if:
/**
 * 1. there is some logic that can be reused, for example useIsErrorState() could be used in many of your components just as an example
 * 2. if there is large or complex logic you want to abstract out, below doesnt count as its specific to UI styling
 * 3. your logic requires the use of other react hooks
 */

// codeReviewSarimDone: this is a fairly light fu nction, I don't see the need for this to be in a hooks file, please put it back where it should be

// codeReviewSarimDone: this is a fairly light function, I don't see the need for this to be in a hooks file, please put it back where it should be

// codeReviewSarimDone: this is a fairly light function, I don't see the need for this to be in a hooks file, please put it back where it should be

// codeReviewSarimDone: instead of making a function in a hooks file.... why don't you make this a component as a subcomponent of your panel and use it accordingly? take the function args as props
