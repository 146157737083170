import React from "react";
import './modal-footer-primary-action.scss';
import PropTypes from "prop-types";
import {Button, Type} from "../../../button";

export default class ModalFooterPrimaryAction extends React.Component {
    render = () => {
        return <Button {...this.props} type={this.props.type} text={this.props.text} shortText={this.props.shortText}/>;
    }
}

ModalFooterPrimaryAction.propTypes = {
    customClasses: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    shortText: PropTypes.any,
    onClick: PropTypes.func,
    type: PropTypes.string
};

ModalFooterPrimaryAction.defaultProps = {
    disabled: false,
    type: Type.PRIMARY,
    customClasses: '',
};
