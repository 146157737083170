import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import type {TrimData} from '@Panels/trim-panels/trim-panels.types';
import type {TrimAudioPanelProps} from '@Panels/trim-panels/trim-audio-panel/trim-audio-panel';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'trim-audio-modal-panel';

export type TrimAudioModalProps = TrimAudioPanelProps;

export function TrimAudioModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.TRIM_AUDIO_MODAL];
  });
  const dispatch = useDispatch();

  const height = '445px';
  const width = '1000px';

  return (
    <Modal modalId={MODALS_IDS.TRIM_AUDIO_MODAL} panelId={PANEL_ID} modalHeight={height} mobileHeight={height} isBottomSheetOnMobile modalWidth={width}>
      <LazyPanel
        panelDirectoryName="trim-panels/trim-audio-panel"
        panelProps={{
          ...modalData.panelProps,
          onTrim: (trimData: TrimData | null): void => {
            dispatch(closeModal(MODALS_IDS.TRIM_AUDIO_MODAL));
            modalData.panelProps.onTrim(trimData);
          },
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openTrimAudioModal = (props: TrimAudioModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.TRIM_AUDIO_MODAL,
      panelProps: props,
    })
  );
};
