import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {UserSchedulePanelProps} from '@Panels/user-schedule-panel/user-schedule-panel';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'user-schedule-modal-panel';

export type UserScheduleModalProps = UserSchedulePanelProps;

export function UserScheduleModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.USER_SCHEDULE_MODAL];
  });
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  const height = sizeData?.height?.length ? sizeData?.height : '620px';
  const width = '1200px';
  const maxHeight = '1000px';
  return (
    <Modal
      isBottomSheetOnMobile
      modalId={MODALS_IDS.USER_SCHEDULE_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      modalWidth={width}
      mobileHeight={height}
      modalMaxHeight={maxHeight}
    >
      <LazyPanel
        panelDirectoryName="user-schedule-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openScheduleModal = (props: UserScheduleModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.USER_SCHEDULE_MODAL,
      panelProps: props,
    })
  );
};
