import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export interface LazyPanelStorage {
  hasFailedToLoad: boolean;
  isLoading?: boolean;
}

interface InitLazyPanelProps {
  lazyPanelId: string;
  hasFailedToLoad?: boolean;
  isLoading?: boolean;
}

export interface SetLazyPanelErrorProps {
  lazyPanelId: string;
  hasFailedToLoad: boolean;
}

export interface SetLazyPanelIsLoadingProps {
  lazyPanelId: string;
  isLoading: boolean;
}

interface StateProps {
  lazyPanelHashmap: Record<string, LazyPanelStorage>;
}

const initialState: StateProps = {
  lazyPanelHashmap: {},
};

export const lazyPanelSlice = createSlice({
  name: 'lazyPanel',
  initialState,
  reducers: {
    initLazyPanel: (state, action: PayloadAction<InitLazyPanelProps>) => {
      state.lazyPanelHashmap[action.payload.lazyPanelId] = {
        hasFailedToLoad: action.payload.hasFailedToLoad ?? false,
        isLoading: action.payload.isLoading ?? false,
      };
    },
    setLazyPanelHasFailedToLoad: (state, action: PayloadAction<SetLazyPanelErrorProps>) => {
      state.lazyPanelHashmap[action.payload.lazyPanelId] = {
        hasFailedToLoad: action.payload.hasFailedToLoad,
        isLoading: false,
      };
    },
    setLazyPanelIsLoading: (state, action: PayloadAction<SetLazyPanelIsLoadingProps>) => {
      state.lazyPanelHashmap[action.payload.lazyPanelId].isLoading = action.payload.isLoading;
    },
  },
});

export const {initLazyPanel, setLazyPanelHasFailedToLoad, setLazyPanelIsLoading} = lazyPanelSlice.actions;
export const lazyPanelReducer = lazyPanelSlice.reducer;
