import type {MouseEventHandler, ReactElement} from 'react';
import React from 'react';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import useWindowSize from '@Hooks/useWindowSize';
import {MOBILE_MODAL_WIDTH_THRESHOLD} from '@Components/modal';
import type {IconProps} from '@Components/icon-v2';
import {Icon} from '@Components/icon-v2';
import styles from './panel-header.module.scss';
import type {PanelHeaderActionProps} from '../panel-header-action';
import {PanelHeaderActions} from '../panel-header-actions';

export interface PanelHeaderProps {
  title?: string;
  titleClasses?: string;
  backButtonIconSize?: IconSize;
  centerAlignTitle?: boolean;
  forceCenterAlignTitleWithActionItems?: boolean;
  showBackButton?: boolean;
  onBackButton?: MouseEventHandler;
  backButtonClassName?: string;
  showCloseButton?: boolean;
  onClose?: MouseEventHandler;
  actions?: Array<PanelHeaderActionProps>;
  className?: string;
  isOpenedInModal?: boolean;
  leftIconProps?: IconProps;
}

export function PanelHeader({
  centerAlignTitle = false,
  titleClasses = '',
  showBackButton = false,
  showCloseButton = true,
  onClose = (): void => {},
  onBackButton = (): void => {},
  title = '',
  actions = [],
  className = '',
  backButtonClassName = 'spacing-m-r-2',
  isOpenedInModal = false,
  forceCenterAlignTitleWithActionItems = false,
  ...props
}: PanelHeaderProps): ReactElement {
  const {windowWidth} = useWindowSize();
  const isMobileModal = (): boolean => {
    return windowWidth < MOBILE_MODAL_WIDTH_THRESHOLD;
  };

  const getBackButtonIconSize = (): IconSize => {
    if (props.backButtonIconSize) {
      return props.backButtonIconSize;
    }

    if (isOpenedInModal && isMobileModal()) {
      return IconSize.SIZE_ICON_20;
    }

    return IconSize.SIZE_ICON_24;
  };

  const getBackButton = (): ReactElement => {
    if (!showBackButton) {
      return <div />;
    }

    return (
      <div className={`${backButtonClassName} ${isMobileModal() && isOpenedInModal ? `${styles.actions} ${styles.backButtonContainer}` : ''}`}>
        <Icon icon="icon-back" shape={IconShape.SQUARE} size={getBackButtonIconSize()} onClick={onBackButton} type={IconType.GHOST} />
      </div>
    );
  };

  const getTextContainer = (): ReactElement => {
    const textComponent = (
      <Text
        size={TextSize.SMALL}
        bold
        className={`${titleClasses} ${styles.title} ${!showBackButton && !centerAlignTitle && !forceCenterAlignTitleWithActionItems ? 'spacing-m-l-4' : ''} ${
          isMobileModal() && isOpenedInModal && !props.leftIconProps ? styles.mobileHeaderContainer : ''
        }`}
        val={title}
      />
    );
    if (props.leftIconProps) {
      return (
        <div className={`flex-h-row flex-align-items-center ${styles.textContainer} ${isMobileModal() && isOpenedInModal ? styles.mobileHeaderContainer : ''}`}>
          <Icon {...props.leftIconProps} />
          {textComponent}
        </div>
      );
    }
    return textComponent;
  };

  return (
    <div className={`${styles.header} ${isOpenedInModal && isMobileModal() ? styles.smallHeader : ''} ${className} ${centerAlignTitle ? styles.centerAlignedTitle : ''}`}>
      {getBackButton()}
      {getTextContainer()}
      <PanelHeaderActions
        closeButtonIconSize={isMobileModal() && isOpenedInModal ? IconSize.SIZE_ICON_20 : IconSize.SIZE_ICON_24}
        actions={centerAlignTitle && !forceCenterAlignTitleWithActionItems ? [] : actions}
        showCloseButton={showCloseButton}
        onClose={onClose}
        className={isMobileModal() && isOpenedInModal ? styles.actions : ''}
      />
    </div>
  );
}
