import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {LazyPanel} from '@Components/lazy-panel';
import {useDispatch} from 'react-redux';
import type {MaskingPanelProps} from '@Panels/masking-panel/masking-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'masking-modal-panel';

export type MaskingModalProps = MaskingPanelProps;

export function MaskingModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.MASKING_MODAL];
  });
  const dispatch = useDispatch();

  const height = '900px';
  const width = '1400px';

  return (
    <Modal modalId={MODALS_IDS.MASKING_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="masking-panel"
        panelProps={{
          ...modalData.panelProps,
          onMaskCTA: (maskData: any): void => {
            dispatch(closeModal(MODALS_IDS.MASKING_MODAL));
            modalData.panelProps.onMaskCTA(maskData);
          },
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openMaskingModal = (props: MaskingModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.MASKING_MODAL,
      panelProps: props,
    })
  );
};
