import type {ReactElement} from 'react';
import React from 'react';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {PremiumAgreementPanelProps} from '@Panels/premium-agreement-panel/premium-agreement-panel.types';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'premium-agreement-modal-panel';

export type PremiumAgreementModalProps = PremiumAgreementPanelProps;

export function PremiumAgreementModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.PREMIUM_AGREEMENT_MODAL];
  });

  const directoryName = 'premium-agreement-panel';
  const height = '500px';
  const width = '1000px';
  const mobileHeight = '500px';

  return (
    <Modal isBottomSheetOnMobile mobileHeight={mobileHeight} modalId={MODALS_IDS.PREMIUM_AGREEMENT_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName={directoryName}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openPremiumAgreementModal = (props: PremiumAgreementModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.PREMIUM_AGREEMENT_MODAL,
      panelProps: props,
    })
  );
};
