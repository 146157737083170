import {MODALS_IDS} from '@Components/modal-container';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';

export const GOOGLE_BUSINESS_PROFILES_IMPORTED_PANEL_ID = 'google-business-profiles-imported-panel';

export const openGoogleBusinessProfilesImportedModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.GOOGLE_BUSINESS_PROFILES_IMPORTED_MODAL,
      panelProps: {},
    })
  );
};
