import {MODALS_IDS} from '@Components/modal-container';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';

export const BUSINESS_PROFILES_HOURS_PANEL_ID = 'business-profiles-hours-panel';

export const openBusinessProfilesHoursModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.BUSINESS_PROFILES_HOURS_MODAL,
      panelProps: {},
    })
  );
};
