import type {BasePanel} from '@Components/panel/panel.types';
import type {VoidFunction} from '@Utils/general.util';

export const ACCOUNT_DELETION_PANEL_ID = 'accountDeletionPanel';
export const USER_BLOCKED = 'user_blocked';

export enum PanelType {
  ACCOUNT_RESTORATION = 'ACCOUNT_RESTOARTION',
  REQUEST_ACCOUNT_DELETION = 'REQUEST_ACCOUNT_DELETION',
  CONFIRM_ACCOUNT_DELETION = 'CONFIRM_ACCOUNT_DELETION',
  CANCEL_ACCOUNT_DELETION = 'CANCEL_ACCOUNT_DELETION',
  CANCEL_UNVERIFIED_ACCOUNT_DELETION_REQUEST = 'CANCEL_UNVERIFIED_ACCOUNT_DELETION_REQUEST',
  ERROR = 'ERROR',
}

export interface AccountDeletionPanelProps extends BasePanel {
  isAccountPendingDeletion?: boolean;
  isEmailSentConfirmation?: boolean;
  errorMessage?: string;
  onSuccessfulCallback?: VoidFunction;
  isUnverifiedRequestCancellation?: boolean;
}

export interface InitiateAccountDeletionFailResponse {
  data?: {
    message: string;
  };
}
