import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {UserTablePanelProps} from '@Panels/user-table-panel/user-table-panel';
import {LazyPanel} from '@Components/lazy-panel';
import useWindowSize from '@/hooks/useWindowSize';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'user-table-modal-panel';
const COLLAPSE_ROW_OPTIONS_SCREEN_WIDTH = 900;

export type UserTableModalProps = UserTablePanelProps;

export function UserTableModal(): ReactElement {
  const {windowWidth} = useWindowSize();
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.USER_TABLE_MODAL];
  });
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  const height = sizeData?.height?.length ? sizeData?.height : '400px';
  const width = sizeData?.width?.length ? sizeData?.width : '400px';

  return (
    <Modal isBottomSheetOnMobile modalId={MODALS_IDS.USER_TABLE_MODAL} panelId={PANEL_ID} mobileHeight={height} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="user-table-panel"
        panelProps={{
          ...modalData.panelProps,
          collpaseRowOptions: !!(modalData.panelProps.collpaseRowOptions ?? windowWidth < COLLAPSE_ROW_OPTIONS_SCREEN_WIDTH),
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openTableModal = (props: UserTableModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.USER_TABLE_MODAL,
      panelProps: props,
    })
  );
};
