import EventEmitter from 'eventemitter3';


export const EVENTS = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    USER_MEDIA_UPLOAD_START: 'USER_MEDIA_UPLOAD_START',
    USER_MEDIA_UPLOAD_SUCCESS: 'USER_MEDIA_UPLOAD_SUCCESS',
    USER_MEDIA_UPLOAD_FAIL: 'USER_MEDIA_UPLOAD_FAIL',
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    BRAND_LOGO_UPLOAD_START: 'ADD_BRAND_ASSET_START',
    BRAND_LOGO_UPLOAD_FAIL: 'ADD_BRAND_ASSET_FAIL',
    BRAND_LOGO_UPLOAD_SUCCESS: 'ADD_BRAND_ASSET_END',
    FONT_UPLOAD_START: 'FONT_UPLOAD_START',
    FONTS_UPLOAD_END: 'FONTS_UPLOAD_END',
}

if (!PMW?.react?.Emitter) {
    const emitter = new EventEmitter();
    PMW.mergePMW(PMW, {
        react: {
            Emitter: {
                on: (event, fn) => emitter.on(event, fn),
                once: (event, fn) => emitter.once(event, fn),
                off: (event, fn) => emitter.off(event, fn),
                emit: (event, payload) => emitter.emit(event, payload)
            }
        }
    });
}

export default PMW.react.Emitter;
