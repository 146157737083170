import type {MouseEventHandler, ReactElement} from 'react';
import React from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Button, Size, Type} from '@Components/button';

export interface PanelHeaderActionProps {
  key?: string;
  text?: string;
  icon?: string;
  className?: string;
  iconSize?: IconSize;
  onClick?: MouseEventHandler;
  title?: string;
}

export function PanelHeaderAction({title = '', text = '', className = '', icon = '', iconSize = IconSize.SIZE_ICON_20, ...props}: PanelHeaderActionProps): ReactElement {
  const getSizeFromIconSize = (): Size => {
    switch (iconSize) {
      case IconSize.SIZE_ICON_16:
        return Size.XSMALL;
      case IconSize.SIZE_ICON_20:
        return Size.MEDIUM;
      case IconSize.SIZE_ICON_24:
        return Size.LARGE;
      default:
        return Size.MEDIUM;
    }
  };

  return <Button text={text} size={getSizeFromIconSize()} icon={icon} title={title} customClasses={className} onClick={props.onClick} type={Type.GHOST} />;
}
