import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {AudioItemRenamePanelProps} from '@Panels/audio-item-rename-panel/audio-item-rename-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'collection-item-more-options-modal-panel';

export type AudioItemRenameModalProps = AudioItemRenamePanelProps;

export function AudioItemRenameModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.AUDIO_ITEM_RENAME_MODAL];
  });

  return (
    <Modal modalId={MODALS_IDS.AUDIO_ITEM_RENAME_MODAL} panelId={PANEL_ID} modalHeight="fit-content" modalWidth="100%">
      <LazyPanel
        panelDirectoryName="audio-item-rename-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openAudioItemRenameModal = (props: AudioItemRenameModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.AUDIO_ITEM_RENAME_MODAL,
      panelProps: props,
    })
  );
};
