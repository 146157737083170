import type {BasePanel} from '@Components/panel/panel.types';
import type {TwoFactorVerificationCallback} from '@Components/two-factor-authentication/two-factor-authentication.types';

export const TWO_FACTOR_AUTHENTICATION_VERIFICATION_PANEL_ID = 'twoFactorAuthenticationVerificationPanel';

export enum TwoFactorVerificationUserActionType {
  PASSWORD_RESET = 'PASSWORD_RESET',
}

export interface TwoFactorAuthenticationVerificationPanelProps extends BasePanel {
  onVerificationSuccessCallback?: TwoFactorVerificationCallback;
  onlyTOTPValid?: boolean;
  userActionType?: TwoFactorVerificationUserActionType;
}
