import type {ReactElement} from 'react';
import React from 'react';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {EditPosterSubtitleMoreOptionsPanelProps} from '@Panels/edit-poster-subtitle-more-options-panel/edit-poster-subtitle-more-options-panel.types';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'edit-poster-subtitle-more-options-panel';

export function EditPosterSubtitleMoreOptionsModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.EDIT_POSTER_SUBTITLE_MORE_OPTIONS_MODAL];
  });

  return (
    <Modal modalId={MODALS_IDS.EDIT_POSTER_SUBTITLE_MORE_OPTIONS_MODAL} panelId={PANEL_ID} modalHeight="fit-content" modalWidth="100%">
      <LazyPanel
        panelDirectoryName={PANEL_ID}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openEditPosterSubtitleMoreOptionsModal = (props: EditPosterSubtitleMoreOptionsPanelProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.EDIT_POSTER_SUBTITLE_MORE_OPTIONS_MODAL,
      panelProps: props,
    })
  );
};
