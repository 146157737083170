import type {AddMailingListModalProps} from '@Modals/add-mailing-list-modal/add-mailing-list-modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';

export * from './add-mailing-list-modal';

export const PANEL_ID = 'add-mailing-list-panel';

export const openAddMailingListModal = (props: AddMailingListModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.ADD_MAILING_LIST_MODAL,
      panelProps: props,
    })
  );
};
