import type {ReactElement} from 'react';
import React, { useState} from 'react';
import type {ContentPlannerCustomEventsPanelParams, EventEntry} from '@Components/content-planner/content-planner.types';
import {Panel} from '@Components/panel';
import {EditEventHeader} from '@Panels/edit-content-planner-event-panel/components/edit-event-header';
import {EditPanelModes} from '@Panels/edit-content-planner-event-panel/edit-content-planner-event-panel.types';
import {useCustomEventFormData, useEntriesMapKey} from '@Components/content-planner/content-planner.hooks';
import {setCustomEventFormData} from '@Components/content-planner/content-planner-reducer';
import {Button, Size, Type} from '@Components/button';
import {closePanel} from '@Components/panel/panel-reducer';
import useCustomPanelClose from '@Panels/hooks/useCustomPanelClose';
import {executeThunk} from '@Utils/thunk.util';
import {deleteCustomEvent, editCustomEvent} from '@Components/content-planner/content-planner-thunk';
import {noop} from '@Utils/general.util';
import {ContentPlannerCustomEventForm} from '@Components/content-planner-custom-event-form';
import {DefaultPanel} from '@Panels/edit-content-planner-event-panel/components/default-panel';
import {useIsMounted} from '@Hooks/useIsMounted';
import {DeletePanel} from '@Panels/edit-content-planner-event-panel/components/delete-panel';
import {areDatesEqual, getDateFromUnixTimestamp} from '@Utils/date.util';
import {EventInputMargin} from '@Components/content-planner-custom-event-form/content-planner-custom-event-form.types';
import {useAppDispatch} from '@/hooks';

export const PANEL_ID = 'edit-content-planner-event-panel';

interface EditContentPlannerEventPanelProps extends ContentPlannerCustomEventsPanelParams {
  event: EventEntry;
  onClosePanel?: () => void;
}

function EditContentPlannerEventPanel({event, onClosePanel, panelId = PANEL_ID, dialogMode = false}: EditContentPlannerEventPanelProps): ReactElement {
  const [panelActiveMode, setActiveMode] = useState<EditPanelModes>(EditPanelModes.DEFAULT);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const formData = useCustomEventFormData();
  const entriesMapKey = useEntriesMapKey();
  const dispatch = useAppDispatch();

  const customClosePanel = (): void => {
    dispatch(closePanel(panelId));
    if (onClosePanel) {
      onClosePanel();
    }
  };

  const onClose = useCustomPanelClose(panelId, customClosePanel);

  const isInEditMode = (): boolean => {
    return panelActiveMode === EditPanelModes.EDIT;
  };

  const isDeletingInDialog = (): boolean => {
    return isInDeleteMode() && dialogMode;
  };

  const shouldDefaultPanelBeDisplayed = (): boolean => {
    return isInDefaultMode() || (!dialogMode && !isInEditMode());
  };

  const isInDeleteMode = (): boolean => {
    return panelActiveMode === EditPanelModes.DELETE;
  };

  const isInDefaultMode = (): boolean => {
    return panelActiveMode === EditPanelModes.DEFAULT;
  };
  const switchToDeleteMode = (): void => {
    setActiveMode(EditPanelModes.DELETE);
  };

  const switchToDefaultMode = (): void => {
    setActiveMode(EditPanelModes.DEFAULT);
  };

  const switchToEditMode = (): void => {
    setActiveMode(EditPanelModes.EDIT);
  };

  const haveEventPropertiesChanged = (): boolean => {
    return (
      event.title !== formData.title ||
      event.description !== formData.description ||
      !areDatesEqual(getDateFromUnixTimestamp(event.timestamp), getDateFromUnixTimestamp(formData.timestamp))
    );
  };

  const saveEdit = async () => {
    return dispatch(
      editCustomEvent({
        entriesMapKey,
        eventData: formData,
        originalTimestamp: event.timestamp,
      })
    );
  };

  const onConfirmEdit = (): void => {
    if (haveEventPropertiesChanged()) {
      executeThunk(saveEdit).catch(noop);
    }
    switchToDefaultMode();
  };

  const onCancelEdit = (): void => {
    dispatch(setCustomEventFormData(event));
    switchToDefaultMode();
  };

  const deleteEvent = async () => {
    return dispatch(
      deleteCustomEvent({
        entriesMapKey,
        eventData: event,
      })
    );
  };

  const onDelete = (): void => {
    setIsLoading(true);
    executeThunk(deleteEvent, () => {
      if (isMounted) {
        onClose();
      }
    }).catch(noop);
  };

  const getForm = (): ReactElement => {
    return (
      <ContentPlannerCustomEventForm
        className="fadeIn _full-height"
        dateTileBtnSpacingClasses={!dialogMode ? 'spacing-p-l-0 spacing-p-r-1' : undefined}
        descriptionInputMargin={!dialogMode ? EventInputMargin.TOP_8 : EventInputMargin.TOP_16}
        openDatePickerInPanel={dialogMode}
        showDatePickerEditIcon={dialogMode}
        showDateTileAtEnd={!dialogMode}
        animateIn={dialogMode}
        smallTextSizeInDateTile={!dialogMode}
      >
        {dialogMode ? <Button customClasses="spacing-m-t-5" type={Type.PRIMARY} size={Size.SMALL} onClick={onConfirmEdit} text={window.i18next.t('pmwjs_done')} /> : null}
      </ContentPlannerCustomEventForm>
    );
  };

  const getPaddingClasses = (): string => {
    const paddingLeft = dialogMode ? 'spacing-p-l-4' : 'spacing-p-l-5';
    const paddingRight = 'spacing-p-r-4';
    let paddingTop = 'spacing-p-t-4';
    let paddingBottom = dialogMode ? 'spacing-p-b-6' : 'spacing-p-b-7';

    if (isDeletingInDialog()) {
      paddingTop = 'spacing-p-t-6';
    } else if (isInDeleteMode()) {
      paddingTop = 'spacing-p-t-4';
      paddingBottom = 'spacing-p-b-5';
    } else if (dialogMode) {
      paddingTop = 'spacing-p-t-5';
    }

    return `${paddingTop} ${paddingBottom} ${paddingLeft} ${paddingRight}`;
  };

  const getContent = (): ReactElement => {
    return (
      <div className={`${getPaddingClasses()} flex-v-row`}>
        {isInEditMode() ? getForm() : null}
        {isDeletingInDialog() ? <DeletePanel eventTitle={formData.title} onDelete={onDelete} onCancel={switchToDefaultMode} isLoading={isLoading} /> : null}
        {shouldDefaultPanelBeDisplayed() ? (
          <DefaultPanel
            isLoading={isLoading}
            onConfirmDelete={onDelete}
            switchToDefaultMode={switchToDefaultMode}
            eventFormData={formData}
            isInEditMode={isInEditMode()}
            isInDeleteMode={isInDeleteMode()}
            switchToEditMode={switchToEditMode}
            switchToDeleteMode={switchToDeleteMode}
            isInModal={dialogMode}
          />
        ) : null}
      </div>
    );
  };

  return (
    <Panel
      panelHeader={
        <EditEventHeader
          switchToEditMode={switchToEditMode}
          switchToDeleteMode={switchToDeleteMode}
          onClose={onClose}
          isInModal={dialogMode}
          isInEditMode={isInEditMode()}
          isInDeleteMode={isInDeleteMode()}
          onConfirmEdit={onConfirmEdit}
          onCancelEdit={onCancelEdit}
        />
      }
      panelContent={getContent()}
      className="radius-8"
    />
  );
}

export default EditContentPlannerEventPanel;
